import Flags from 'country-flag-icons/react/3x2'
import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';
import LangSelector from './LangSelector/LangSelector';

class Navbar extends Component {

  getInitialLogo = () => window.innerWidth >= 992 ? 'img/icons/logo/01_alt.png' : 'img/icons/logo/01.png';

  onScrollHandler = () => {
    if (window.scrollY > 100) {
      this.setState({
        navbarScrolledClass: 'navbar-scrolled',
        logo: 'img/icons/logo/01.png'
      })
    } else if (window.innerWidth >= 992) {
      this.setState({
        navbarScrolledClass: '',
        logo: 'img/icons/logo/01_alt.png'
      })
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onScrollHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollHandler);
  }

  state = {
    navbarScrolledClass: '',
    logo: this.getInitialLogo(),
    selectedLang: 'hu'
  };

  onLangSelect = lang => {
    i18n.changeLanguage(lang);
    this.setState({ selectedLang: lang });
  };

  render() {
    const { t: translate } = this.props;
    const navbarItemLocations = [
      '#about',
      '#portfolio',
      '#services',
      '#contact'
    ];
    const translations = (translate('navbar', { returnObjects: true }));
    const navbarItems = Object.keys(translations).map((key, index) => {
      return {
        id: index,
        text: translations[key],
        location: navbarItemLocations[index]
      }
    });

    const selectedLangIcon = this.state.selectedLang === 'hu'
      ? <Flags.HU title="HU" className="flag-button"/>
      : <Flags.GB title="EN" className="flag-button"/>;

    return (
      <nav className={'navbar navbar-expand-lg navbar-light fixed-top py-1 ' + this.state.navbarScrolledClass}
           id="mainNav">
        <div className="container">
          <AnchorLink href="#page-top">
            <img src={this.state.logo} alt="brand-logo" className="navbar-brand" style={{ height: '4rem' }}/>
          </AnchorLink>
          <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
                  data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                  aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"/>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto my-2 my-lg-0">
              {Object.keys(navbarItems).map(item =>
                <li className="nav-item" key={navbarItems[item].id}>
                  <AnchorLink
                    className="nav-link"
                    href={navbarItems[item].location}
                    offset={() => document.getElementById('mainNav').getBoundingClientRect().height}>
                    {navbarItems[item].text}
                  </AnchorLink>
                </li>)}
              <li>
                <LangSelector
                  selectedLangIcon={selectedLangIcon}
                  onLangSelect={this.onLangSelect}/>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default withTranslation()(Navbar);