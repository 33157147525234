import React from 'react';
import { withTranslation } from 'react-i18next';
import ServiceModal from './ServiceModal';

const Services = ({ t: translate }) => {
  const imgPrefix = 'img/icons/services/';
  const icons = [
    {
      src: imgPrefix + '01.png',
      alt: 'design'
    }, {
      src: imgPrefix + '02.png',
      alt: 'quality'
    }, {
      src: imgPrefix + '03.png',
      alt: 'furniture'
    }
  ];
  const translations = (translate('services.items', { returnLists: true, returnObjects: true }));
  const serviceItems = Object.keys(translations).map((key, index) => {
    return {
      id: index,
      title: translations[key].title,
      body: translations[key].body,
      icon: icons[index],
      buttonText: translate('services.modalButton'),
      modalHeader: translations[key].title,
      modalBody: translations[key].modalBody
    }
  });

  return (
    <section className="page-section" id="services">
      <div className="container">
        <h2 className="text-center mt-0">{translate('services.title')}</h2>
        <hr className="divider my-4"/>
        <div className="row">
          {Object.keys(serviceItems).map(i =>
            <div className={'col-lg-' + (12 / serviceItems.length) + ' col-md-6 text-center'} key={serviceItems[i].id}>
              <div className="mt-5">
                <img src={serviceItems[i].icon.src} alt={serviceItems[i].icon.alt}/>
                <h3 className="h4 mb-2" style={{margin: "5%"}}>{serviceItems[i].title}</h3>
                <p className="text-muted mb-0">{serviceItems[i].body}</p>
                <p> </p>
                <ServiceModal
                  buttonText={serviceItems[i].buttonText}
                  title={serviceItems[i].modalHeader}
                  body={serviceItems[i].modalBody}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default withTranslation()(Services);