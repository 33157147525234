import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';

const MasterHeader = () => {
  const { t: translate } = useTranslation();

  return (
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <h1 className="text-uppercase text-white font-weight-bold">{translate('masterHeader.title')}</h1>
            <hr className="divider my-4"/>
          </div>
          <div className="col-lg-8 align-self-baseline">
            <p className="text-white-75 font-weight-light mb-5">{translate('masterHeader.body')}</p>
            <AnchorLink className="btn btn-primary btn-xl"
                        href="#about"
                        offset={() => document.getElementById('mainNav').getBoundingClientRect().height}>
            {translate('masterHeader.callToAction')}
            </AnchorLink>
          </div>
        </div>
      </div>
    </header>
  );
};

export default MasterHeader;