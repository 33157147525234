import React from 'react';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t: translate } = useTranslation();

  return (
    <footer className="bg-dark">
      <div className="container">
        <div className="small text-center text-white-50">{translate('footer.copyRight')}</div>
      </div>
    </footer>

  );
};

export default Footer;