import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

class ServiceModal extends Component {
  state = {
    show: false
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  render() {
    return (
      <div>
          <Button className="btn btn-sm" onClick={this.handleShow}>
            {this.props.buttonText}
          </Button>
          <Modal show={this.state.show} onHide={this.handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title className="text-white font-weight-bold">{this.props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.body.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
            </Modal.Body>
          </Modal>
      </div>
    );
  }
}

export default ServiceModal;