import React, { Suspense } from 'react';
import './App.css';
import AboutUs from './components/AboutUs/AboutUs';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import MasterHeader from './components/MasterHeader/MasterHeader';
import Meta from './components/Meta/Meta';
import Navbar from './components/Navbar/Navbar';
import Portfolio from './components/Portfolio/Portfolio';
import Services from './components/Services/Services';
import './i18n'

function App() {

  return (
    <Suspense fallback={null}>
      <div className="app">
        <Meta/>
        <Navbar/>
        <MasterHeader/>
        <AboutUs/>
        <Portfolio/>
        <Services/>
        <Contact/>
        <Footer/>
      </div>
    </Suspense>
  );
}

export default App;
