export const getImages = async t => {
  const images = await Promise.all(pictures.map(picture => loadImage(picture)));
  return images.map((image, index) => ({
      id: index + 1,
      index,
      category: t(image.category),
      fullSize: {
        title: t(image.title),
        src: image.src,
        w: image.width,
        h: image.height
      }
    })
  );
};

const loadImage = (picture) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve({
      ...picture,
      width: img.width,
      height: img.height
    });
    img.onerror = reject;
    img.src = picture.src;
  })
};

const pictures = [
  { src: '/img/portfolio/1.jpg', category: 'portfolio.categories.bedroom', title: 'portfolio.pictureNames.kingSizeBed' },
  { src: '/img/portfolio/2.jpg', category: 'portfolio.categories.bedroom', title: 'portfolio.pictureNames.wardrobe' },
  { src: '/img/portfolio/3.jpg', category: 'portfolio.categories.bedroom', title: 'portfolio.pictureNames.wardrobe' },

  { src: '/img/portfolio/4.jpg', category: 'portfolio.categories.bedroom', title: 'portfolio.pictureNames.wardrobe' },
  { src: '/img/portfolio/foldable_desk_02.jpg', category: 'portfolio.categories.bedroom', title: 'portfolio.pictureNames.foldableDesk' },
  { src: '/img/portfolio/foldable_desk_03.jpg', category: 'portfolio.categories.bedroom', title: 'portfolio.pictureNames.foldableDesk' },

  { src: '/img/portfolio/13.jpg',category: 'portfolio.categories.upstairsRoom', title: 'portfolio.pictureNames.wardrobe' },
  { src: '/img/portfolio/16.jpg',category: 'portfolio.categories.upstairsRoom', title: 'portfolio.pictureNames.wardrobe' },
  { src: '/img/portfolio/15.jpg',category: 'portfolio.categories.upstairsRoom', title: 'portfolio.pictureNames.wardrobe' },

  //Not on the page
  { src: '/img/portfolio/17.jpg',category: 'portfolio.categories.lobby', title: 'portfolio.pictureNames.cabinet' },
  { src: '/img/portfolio/18.jpg',category: 'portfolio.categories.livingRoom', title: 'portfolio.pictureNames.bookShelfWithSpace' },
  { src: '/img/portfolio/19.jpg',category: 'portfolio.categories.kitchen', title: 'portfolio.pictureNames.kitchenUpperShelf' },
  { src: '/img/portfolio/20.jpg',category: 'portfolio.categories.lobby', title: 'portfolio.pictureNames.storage' },
  { src: '/img/portfolio/14.jpg',category: 'portfolio.categories.upstairsRoom', title: 'portfolio.pictureNames.wardrobe' },
  { src: '/img/portfolio/8.jpg', category: 'portfolio.categories.lobby', title: 'portfolio.pictureNames.childBed' },
  { src: '/img/portfolio/9.jpg', category: 'portfolio.categories.kitchen', title: 'portfolio.pictureNames.kitchen' },
  { src: '/img/portfolio/10.jpg', category: 'portfolio.categories.kitchen', title: 'portfolio.pictureNames.kitchen' },
  { src: '/img/portfolio/11.jpg', category: 'portfolio.categories.livingRoom', title: 'portfolio.pictureNames.catHouse' },
  { src: '/img/portfolio/12.jpg', category: 'portfolio.categories.lobby', title: 'portfolio.pictureNames.cabinet' },
  { src: '/img/portfolio/foldable_desk_01.jpg', category: 'portfolio.categories.bedroom', title: 'portfolio.pictureNames.foldableDesk' },
  { src: '/img/portfolio/5.jpg', category: 'portfolio.categories.garage', title: 'portfolio.pictureNames.garageShelf' },
  { src: '/img/portfolio/6.jpg', category: 'portfolio.categories.garage', title: 'portfolio.pictureNames.clampHolder' },
  { src: '/img/portfolio/7.jpg', category: 'portfolio.categories.garage', title: 'portfolio.pictureNames.clampHolder' },
  { src: '/img/portfolio/bathroom_closet_01.jpg', category: 'portfolio.categories.bathRoom', title: 'portfolio.pictureNames.cabinet' },
  { src: '/img/portfolio/21_ejjeliszekreny.jpg', category: 'portfolio.categories.bedRoom', title: 'portfolio.pictureNames.nightstand' },
  { src: '/img/portfolio/22_furdoszoba_szekreny_01.jpg', category: 'portfolio.categories.bathRoom', title: 'portfolio.pictureNames.cabinet' },
  { src: '/img/portfolio/23_furdoszoba_szekreny_02.jpg', category: 'portfolio.categories.bathRoom', title: 'portfolio.pictureNames.cabinet' },
  { src: '/img/portfolio/24_szekreny.jpg', category: 'portfolio.categories.livingRoom', title: 'portfolio.pictureNames.cabinet' },
  //2021.09.08.
  { src: '/img/portfolio/210908/E01.jpg', category: 'portfolio.categories.lobby', title: 'portfolio.pictureNames.lobbyCabinetWithSittingAndMirrorAndLights' },
  { src: '/img/portfolio/210908/E02.jpg', category: 'portfolio.categories.lobby', title: 'portfolio.pictureNames.lobbyCabinetWithDrawer' },
  { src: '/img/portfolio/210908/F01.jpg', category: 'portfolio.categories.bathRoom', title: 'portfolio.pictureNames.bathroomCabinets' },
  { src: '/img/portfolio/210908/KF01.jpg', category: 'portfolio.categories.bathRoom', title: 'portfolio.pictureNames.bathroomCabinets' },
  { src: '/img/portfolio/210908/G01.jpg', category: 'portfolio.categories.bedRoom', title: 'portfolio.pictureNames.wardrobeWithDrawers' },
  { src: '/img/portfolio/210908/G02.jpg', category: 'portfolio.categories.bedRoom', title: 'portfolio.pictureNames.wardrobeWithIroningBoardAndLights' },
];
