import React from 'react';

const PortfolioImage = props => {
  return (
    <div className="col-lg-4 col-sm-6" onClick={props.clickHandler}>
      <a className="portfolio-box" href={props.thumbNailSrc}>
        <img className="img-fluid" src={props.fullSizeSrc} alt="" width={'650px'} height={'350px'}/>
        <div className="portfolio-box-caption">
          <div className="project-category text-white-50">{props.category}</div>
          <div className="project-name">{props.name}</div>
        </div>
      </a>
    </div>
  );
};

export default PortfolioImage;