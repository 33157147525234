import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next'

const Meta = () => {
  const { t: translate } = useTranslation();

  return (
    <Helmet>
      <title>{translate('title')}</title>

      <meta charSet="utf-8"/>
      <meta name="author" content="st"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="theme-color" content="#000000"/>
      <meta name="description" content="ST Webpage"/>

      <link href="https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700"
            rel="stylesheet"/>
      <link href="https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic"
            rel="stylesheet"
            type="text/css"/>
      <script src="https://use.fontawesome.com/releases/v5.13.0/js/all.js" crossOrigin="anonymous"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/js/bootstrap.bundle.min.js"></script>
    </Helmet>
  );
};

export default Meta;