import Flags from 'country-flag-icons/react/3x2';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownToggle from 'react-bootstrap/DropdownToggle';

const LangSelector = ({ selectedLangIcon, onLangSelect }) => (
  <Dropdown title="language" className="nav-item">
    <DropdownToggle className="lang-select">
      {selectedLangIcon}
    </DropdownToggle>
    <DropdownMenu className="lang-dropdown">
      <Dropdown.Item
        className="lang-item"
        onClick={() => onLangSelect('hu')}
      >
        <Flags.HU className="flag"/>
        <span className="lang">HU</span>
      </Dropdown.Item>
      <Dropdown.Item
        className="lang-item"
        onClick={() => onLangSelect('en')}
      >
        <Flags.GB className="flag"/>
        <span className="lang">EN</span>
      </Dropdown.Item>
    </DropdownMenu>
  </Dropdown>
);

export default LangSelector;