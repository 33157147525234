import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { t: translate } = useTranslation();
  return (
    <section className="page-section bg-dark" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9 text-center">
            <h2 className="text-white mt-0">{translate('aboutUs.title')}</h2>
            <hr className="divider light my-4"/>
            <p className="text-white-50 mb-1">{translate('aboutUs.body1')}</p>
            <p className="text-white-50 mb-5">{translate('aboutUs.body2')}</p>
            <div className="align-content-center">
              <img className="img-fluid team" src={'/img/introduction.jpg'} alt="the_team"/>
            </div>
            <div style={{ height: '8%' }}/>
            <AnchorLink className="btn btn-light btn-xl"
                        href="#services"
                        offset={() => document.getElementById('mainNav').getBoundingClientRect().height}>
              {translate('aboutUs.callToAction')}
            </AnchorLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;