import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t: translate } = useTranslation();
  const imgPrefix = 'img/icons/contact/';

  return (
    <section className="page-section bg-dark text-white" id="contact">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="mt-0">{translate('contact.title')}</h2>
            <hr className="divider my-4"/>
            <p className="text-white-50 mb-5">{translate('contact.body')}</p>
          </div>
        </div>
        <div className="row" style={{paddingBottom: '5%'}}>
          <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
            <img src={imgPrefix + 'phone.png'} alt="phone"/>
            <div>{translate('contact.phone1')}</div>
            <div>{translate('contact.phone2')}</div>
          </div>
          <div className="col-lg-4 mr-auto text-center">
            <img src={imgPrefix + 'mail.png'} alt="e-mail"/>
            <a className="d-block" href={'mailto:' + translate('contact.email')}>{translate('contact.email')}</a>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <p className="text-white-50 mb-1">{translate('contact.companyName')}</p>
            <p className="text-white-50 mb-5">{translate('contact.companyAddress')}</p>
          </div>
        </div>
      </div>
    </section>

  );
};

export default Contact;