import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { PhotoSwipe } from 'react-photoswipe';
import 'react-photoswipe/lib/photoswipe.css';
import i18n from '../../i18n';
import { getImages } from '../../util/PictureUtil';
import PortfolioImage from './PortfolioImage/PortfolioImage';

class Portfolio extends PureComponent {

  state = {
    isOpen: false,
    options: {},
    images: []
  };

  componentDidMount() {
    const { t } = this.props;
    getImages(t).then(images => this.setState({ images }));
    i18n.on(
      'languageChanged',
      () => getImages(t).then(images => this.setState({ images }))
    );
  }

  handleOpen = (event, imageIndex) => {
    event.preventDefault();
    this.setState({
      isOpen: true,
      options: {
        showHideOpacity: true,
        bgOpacity: 0.75,
        closeOnScroll: false,
        index: imageIndex
      }
    });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <div id="portfolio">
        <div className="container-fluid p-0">
          {this.state.images &&
          <PhotoSwipe isOpen={this.state.isOpen}
                      items={this.state.images.map(image => image.fullSize)}
                      options={this.state.options}
                      onClose={this.handleClose}
          />
          }
          <div className="row no-gutters">
            {this.state.images &&
            this.state.images.map(image =>
              image.id <= 9
                ? <PortfolioImage
                  key={image.index}
                  thumbNailSrc={image.fullSize.src}
                  fullSizeSrc={image.fullSize.src}
                  category={image.category}
                  name={image.fullSize.title}
                  clickHandler={(event) => this.handleOpen(event, image.index)}
                />
                : null
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Portfolio);
